@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: WRAP ALL CUSTOM CLASSES IN @layer component {} */

body {
  @apply bg-white text-off-black;
}

h1 {
  @apply text-5xl font-bold lg:text-6xl;
}

/* BUTTONS */

button:disabled {
  @apply bg-fl-gray-50 text-fl-gray-500;
}

.button-rounded {
  @apply m-1 rounded-full px-4 py-2 text-center text-base;
}

.button-default {
  @apply bg-fl-green text-off-black hover:enabled:bg-fl-green-50 active:enabled:ring-1 active:enabled:ring-fl-green-200 focus-visible:active:enabled:bg-fl-green-200;
}

a.button-default {
  @apply hover:bg-fl-green-50 active:ring-1 active:ring-fl-green-200 focus-visible:active:bg-fl-green-200;
}

.button-secondary {
  @apply bg-fl-yellow text-off-black hover:enabled:bg-fl-yellow-50 active:enabled:ring-1 active:enabled:ring-fl-yellow-200 focus-visible:active:enabled:bg-fl-yellow-200;
}

a.button-secondary {
  @apply hover:bg-fl-yellow-50 active:ring-1 active:ring-fl-yellow-200 focus-visible:active:bg-fl-yellow-200;
}

.button-3d {
  @apply my-4 flex items-center rounded-full bg-fl-yellow p-3 font-medium text-off-black shadow-md hover:enabled:bg-fl-yellow-0 active:enabled:ring-2 active:enabled:ring-fl-yellow-200 focus-visible:active:enabled:bg-fl-yellow-200;
  background-image: radial-gradient(#ffffff66 20%, transparent 100%);
}

a.button-3d {
  @apply hover:bg-fl-yellow-0 active:ring-2 active:ring-fl-yellow-200 focus-visible:active:bg-fl-yellow-200;
}

/* INPUT */

input {
  @apply bg-white text-black;
}

.input-wrapper {
  @apply flex-1 flex-col;
}
.input-label {
  @apply mx-px block flex-1;
}
.input-default {
  @apply w-full flex-1 rounded border border-fl-gray-100 px-1.5 py-0.5;
}

.input-required-star {
  @apply ml-0.5;
}
.input-hint {
  @apply my-2 text-xs;
}

.input-error {
  @apply text-xs text-error;
}

/* SELECT */

.select-default {
  @apply mx-1 cursor-pointer rounded-full bg-off-white px-4 py-2 text-center;
}

/* ROOT */
#sidebar a.active {
  @apply underline decoration-fl-green decoration-2 underline-offset-4;
}

/* AUTH PAGES */

.auth-wrapper {
  @apply min-h-screen bg-off-black text-white;
}

.auth-wrapper a {
  @apply font-bold text-fl-green hover:underline focus:underline active:text-fl-green-200;
}

.auth-heading {
  @apply my-2 text-center text-3xl font-extralight text-fl-yellow-100;
}
.auth-text {
  @apply my-1 text-center leading-relaxed;
}

.auth-form {
  @apply mt-4 flex flex-col items-stretch gap-y-4;
}

.auth-form .form-error {
  @apply my-2 rounded-lg bg-error p-2 text-center text-white;
}

.auth-form .form-sucess {
  @apply my-2 rounded-lg bg-error p-2 text-center text-white;
}

.auth-form .form-row {
  @apply flex flex-col gap-4 sm:flex-row;
}

.auth-form .form-col {
  @apply flex w-full max-w-xs flex-col gap-4 self-center;
}

.auth-form .input-hint {
  @apply py-0.5 text-fl-yellow-100;
}

.auth-form .input-required-star {
  @apply text-fl-yellow-100;
}

/* CONNECTION LANDING PAGE */
.hero-text-container {
  @apply mx-auto w-full px-2 md:px-8 lg:px-0;
}
.big-intro-text {
  @apply py-6 text-xl font-light md:py-9 lg:text-2xl;
}

/* -- */

.featured-action-container {
  @apply mx-auto my-2 flex flex-col gap-8 rounded-3xl bg-off-white p-4 sm:p-8 md:gap-12 md:p-12 lg:my-4;
}

.featured-integrations-container {
  @apply flex flex-col items-center justify-center gap-6 md:flex-row lg:gap-9;
}

.featured-integration-card {
  @apply flex w-full flex-col gap-4 rounded-3xl bg-white p-6 shadow-lg;
}

.steps-container {
  @apply flex justify-center;
}

.card-dark {
  @apply flex w-full flex-row rounded-2xl bg-fl-gray-600 text-white;
}

/* -- */

.loader {
  color: #2B2B2B;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em,
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 
    0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, 
    -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, 
    -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
    -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, 
    -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
    -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, 
    -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 
    0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}


/* -- */